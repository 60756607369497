/* eslint-disable react-hooks/exhaustive-deps */
import React from "react"
import Select from "react-select"

import { ClipLoader } from "react-spinners"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Label } from "reactstrap"

import InputTitleGroup from "../../components/InputTitleGroup.js"

// Controllers
import { QuestionController } from "../../controllers/QuestionController.js"

// Assets
import "./new-question.scss"
import { questionLevels, questionTestaments } from "../../constants/questions.js"

function NewQuestionModal({
  status,
  content = null,
  questionClasses,
  questionCategories,
  onClose,
  onConfirm,
}) {
  const initialQuestion = {
    questionName: "",
    questionRef: "",
    questionAlternatives: [],
    questionLevelId: null,
    questionClassId: null,
    questionCategoryId: null,
    questionTestamentId: null,
  }

  const [question, setQuestion] = React.useState(initialQuestion)

  const [loadingQuestion, setLoadingQuestion] = React.useState(false)
  const [actionSearchPanel, setActionSearchPanel] = React.useState({ show: false, position: 0 })

  const [questions, setQuestions] = React.useState([])

  React.useEffect(() => {
    if (!status) {
      setQuestion(initialQuestion)
    }
  }, [status])

  React.useEffect(() => {
    if (content) {
      setLoadingQuestion(true)
      QuestionController.getOneQuestion(content?.questionId)
        .then((response) => {
          if (response.status === 200) {
            const question = response?.content || initialQuestion

            const questionAlternatives = question.questionAlternatives.sort(
              (a, b) => b.alternativeCorrect - a.alternativeCorrect,
            )

            setQuestion({
              questionId: question.questionId,
              questionName: question.questionName,
              questionRef: question.questionRef,
              questionLevelId: question.questionLevelId,
              questionClassId: question.questionClassId,
              questionCategoryId: question.questionCategoryId,
              questionTestamentId: question.questionTestamentId,
              questionAlternatives,
            })
          } else {
            alert("Erro ao consultar dados da pergunta (1)")
          }
        })
        .catch((error) => {
          console.error("Erro ao consultar dados da pergunta", error)
          alert("Erro ao consultar dados da pergunta (2)")
        })
        .finally(() => {
          setLoadingQuestion(false)
        })
    }
  }, [content])

  const onChangeTitle = (questionName) => {
    setQuestion({ ...question, questionName })
    onSearch(questionName)
  }

  const onChangeReference = (questionRef) => {
    setQuestion({ ...question, questionRef })
  }

  const onChangeAlternative = (alternativeIndex, alternativeName, alternativeCorrect = false) => {
    question.questionAlternatives[alternativeIndex] = {
      ...question.questionAlternatives[alternativeIndex],
      alternativeName,
      alternativeCorrect,
    }
    setQuestion({ ...question })
  }

  const onChangeLevel = (level) => {
    setQuestion({ ...question, questionLevelId: level.levelId })
  }

  const onChangeTestament = (testament) => {
    setQuestion({ ...question, questionTestamentId: testament.testamentId })
  }

  const onChangeClass = (questionClass) => {
    setQuestion({ ...question, questionClassId: questionClass.questionClassId })
  }

  const onChangeCategory = (questionCategory) => {
    setQuestion({ ...question, questionCategoryId: questionCategory.questionCategoryId })
  }

  const onSearch = (value) => {
    if (value === "") {
      setQuestions([])
      setActionSearchPanel({ show: false, position: 0 })
    } else if (
      value.length > 2 &&
      (actionSearchPanel.position === 0 || value.length < actionSearchPanel.position)
    ) {
      getQuestions({ searchValue: value, pageSize: 5 })
    }
  }

  const getQuestions = (filter = {}) => {
    QuestionController.getAllQuestions(filter)
      .then((response) => {
        if (response.status === 200) {
          const questions = response.content.questions || []
          setQuestions(questions)

          if (questions.length > 0) {
            if (!actionSearchPanel.show) {
              setActionSearchPanel({ show: true, position: 0 })
            }
          } else {
            setActionSearchPanel({ show: false, position: filter.searchValue.length })
          }
        } else {
          alert("Erro ao consultar as perguntas (1)")
        }
      })
      .catch((error) => {
        console.error("Erro ao consultar as perguntas", error)
      })
  }

  return (
    <Modal isOpen={status} toggle={onClose} className="new-question-modal" size="lg">
      <ModalHeader toggle={onClose} className="new-question-modal--header">
        Cadastro de Perguntas
      </ModalHeader>
      <ModalBody className="new-question-modal--body">
        <InputTitleGroup
          type="textarea"
          title="Título"
          placeholder="Título"
          value={question.questionName}
          onChange={(event) => onChangeTitle(event.target.value)}
        />
        {actionSearchPanel.show && (
          <div className="action-search-panel">
            {questions.map((question, index) => (
              <div key={index} className="action-search-panel--item">
                <label className="action-search-panel--item--name">{question.questionName}</label>
              </div>
            ))}
          </div>
        )}
        <InputTitleGroup
          width="48%"
          title="Resposta"
          placeholder="Resposta"
          maxLength={30}
          value={question.questionAlternatives[0]?.alternativeName || ""}
          onChange={(event) => onChangeAlternative(0, event.target.value, true)}
        />
        <InputTitleGroup
          width="48%"
          maxLength={30}
          title="Alternativa 1"
          placeholder="Alternativa 1"
          value={question.questionAlternatives[1]?.alternativeName || ""}
          onChange={(event) => onChangeAlternative(1, event.target.value)}
        />
        <InputTitleGroup
          width="48%"
          maxLength={30}
          title="Alternativa 2"
          placeholder="Alternativa 2"
          value={question.questionAlternatives[2]?.alternativeName || ""}
          onChange={(event) => onChangeAlternative(2, event.target.value)}
        />
        <InputTitleGroup
          width="48%"
          maxLength={30}
          title="Alternativa 3"
          placeholder="Alternativa 3"
          value={question.questionAlternatives[3]?.alternativeName || ""}
          onChange={(event) => onChangeAlternative(3, event.target.value)}
        />
        <InputTitleGroup
          width="48%"
          maxLength={30}
          title="Alternativa 4"
          placeholder="Alternativa 4"
          value={question.questionAlternatives[4]?.alternativeName || ""}
          onChange={(event) => onChangeAlternative(4, event.target.value)}
        />
        <InputTitleGroup
          width="48%"
          title="Referência"
          placeholder="Referência (Ex: JO 3:16)"
          value={question.questionRef || ""}
          onChange={(event) => onChangeReference(event.target.value)}
        />
        <div className="level-select" style={{ width: "48%" }}>
          <Label>Nível</Label>
          <Select
            options={questionLevels}
            value={
              questionLevels.find((level) => level.levelId === question.questionLevelId) || null
            }
            onChange={onChangeLevel}
            placeholder="Escolha o nível"
            getOptionValue={(option) => option.levelId}
            getOptionLabel={(option) => option.levelName}
          />
        </div>
        <div className="level-select" style={{ width: "48%" }}>
          <Label>Testamento</Label>
          <Select
            options={questionTestaments}
            value={
              questionTestaments.find(
                ({ testamentId }) => testamentId === question?.questionTestamentId,
              ) || null
            }
            onChange={onChangeTestament}
            placeholder="Escolha o testamento"
            getOptionValue={(option) => option.testamentId}
            getOptionLabel={(option) => option.testamentName}
          />
        </div>
        <div className="level-select" style={{ width: "48%" }}>
          <Label>Classificação</Label>
          <Select
            options={questionClasses}
            value={
              questionClasses.find(
                ({ questionClassId }) => questionClassId === question?.questionClassId,
              ) || null
            }
            onChange={onChangeClass}
            placeholder="Escolha a classificação"
            getOptionValue={(option) => option.questionClassId}
            getOptionLabel={(option) => option.questionClassName}
          />
        </div>
        <div className="level-select" style={{ width: "48%" }}>
          <Label>Categoria</Label>
          <Select
            options={questionCategories}
            value={
              questionCategories.find(
                ({ questionCategoryId }) => questionCategoryId === question?.questionCategoryId,
              ) || null
            }
            onChange={onChangeCategory}
            placeholder="Escolha a categoria"
            getOptionValue={(option) => option.questionCategoryId}
            getOptionLabel={(option) => option.questionCategoryName}
          />
        </div>

        {loadingQuestion ? (
          <div className="new-question-modal--body--loading">
            <ClipLoader color={"#50526a"} loading={true} size={100} />
            <Label>Carregando pergunta...</Label>
          </div>
        ) : (
          ""
        )}
      </ModalBody>
      <ModalFooter className="new-question-modal--footer">
        <Button
          color="secondary"
          onClick={() => setQuestion({ questionName: "", questionAlternatives: [], level: null })}
        >
          <FontAwesomeIcon icon="eraser" />
          &nbsp;Limpar
        </Button>
        <Button color="success" onClick={() => onConfirm(question)}>
          <FontAwesomeIcon icon="check" />
          &nbsp;Salvar
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default NewQuestionModal
